::selection {
    color: $base-bg-color;
    background: $brand-color;
}

.follower {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    border-radius: 50%;
    pointer-events: none;
    transform-origin: center;
    width: 8rem;
    height: 8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    @media (prefers-reduced-motion) {
        display: none;
    }

    .follower__content {
        color: $base-bg-color;
        font-size: 1rem;
        font-weight: 400;
        height: 0;
        display: flex;
        align-items: center;
        overflow: hidden;
        position: relative;
        white-space: nowrap;
        transform-origin: center;
    }

    .follower__before,
    .follower__after {
        content: "";
        position: absolute;
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        transform-origin: center;
    }
    .follower__after {
        --alpha:.2;
        background-color: $brand-color;
        mix-blend-mode: darken;
        opacity: .7;
        box-shadow: 0 0 1px $brand-rgba;
    }

    .follower__before {
        background-color: $border-color;
        opacity: 0;
    }
}

body:not(:hover) {
    .follower {
        display: none;
    }
}




