#page-footer {
    padding-top: 2rem;
    padding-bottom: ($navbar-height * 1.5);
    --alpha: .15;
    background-color: $brand-rgba;
    font-size: .9em;

    @media (min-width: $nav-breakpoint) {
        padding-bottom: 2rem;
    }

    a {
        text-decoration: none;
    }

    .menu {
        padding: 0;
        list-style-type: none;

        &.legal {
            margin-top: 3em;
            gap: 1rem;
        }

        li {
            margin-bottom: .7em;
        }

        a {
            display: inline-block;
            padding-bottom: .3em;
            line-height: 1.15;
        }
    }

    .ize-fire {
        .flex {
            margin: 1em 0;
            gap: 1em;
            line-height: 1.1;
        }
    }

}

#ize-fire-logo {
    width: 60px;
    fill: $base-color;
}

.contact {
    padding: 0;
    list-style-type: none;

    li {
        gap: .5em;
    }
    a {
        display: inline-block;
        padding: .2em 0;
    }
    svg {
        //height: 1.3rem;
    }
}