.accordion {

    details {
        border-bottom: 1px solid $border-color;

        &:first-of-type {
            border-top: 1px solid $border-color;
        }

        > .title {
            color: $headline-color;
            position: relative;
            padding: 1.2em 2em 1.2em .5em;
            list-style: none;
            cursor: pointer;
            line-height: 1.25;

            &::before,
            &::after {
                content: "";
                display: block;
                width: .8em;
                height: 1px;
                background-color: $base-color;
                position: absolute;
                top: 50%;
                right: .5em;
            }

            &::after {
                transform: rotate(90deg);
            }

            &::-webkit-details-marker {
                display: none;
            }

        }

        &[open] > .title::after {
            opacity: 0;
        }

    }

    .content {
        padding: 0 .5em 1em;
        font-size: .9em;
    }

}

.cssanim {
    details {
        > .title {
            &::after {
                transition: opacity 250ms linear;
            }
        }
    }
}