.page-header {
    position: relative;
    padding: 10vh 2vw 5vh 5vw;
    color: $overlay-color;
    background-color: $base-color;

    @media screen and (min-width: $nav-breakpoint) {
        min-height: 70vh;
        margin-left: 5vw;
    }

    .headline {
        color: inherit;
        text-shadow: 0 0 10px rgba(0, 0, 0, .1);
        position: relative;
        z-index: 1;
    }

    .image {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 0;
    }
}

.header {
    position: relative;
    z-index: 1;
    padding-top: 10vh;
    padding-bottom: 2em;
    @media (min-width: $nav-breakpoint) {
        grid-template-columns: 3fr 1fr;
    }

    .anchors {
        list-style-type: none;
        align-self: end;

        a {
            display: inline-block;
            position: relative;

            &::before {
                content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0xMSAyMS44ODNsLTYuMjM1LTcuNTI3LS43NjUuNjQ0IDcuNTIxIDkgNy40NzktOS0uNzY0LS42NDUtNi4yMzYgNy41Mjl2LTIxLjg4NGgtMXYyMS44ODN6Ii8+PC9zdmc+");
                position: absolute;
                left: 0;
                bottom: 0;
                opacity: 0;
                pointer-events: none;
            }

            &:hover::before {
                opacity: 1;
                transform: translate(-25px, 7px);
            }
        }
    }
}

.banner {
    display: block;
    height: 300px;
    overflow: hidden;
    @media (min-width: $nav-breakpoint) {
        height: 400px;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.cssanim {
    .header {
        .anchors {
            a::before {
                transition: transform 200ms, opacity 250ms;
            }
        }
    }
}