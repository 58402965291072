.teaser {

    .item {
        display: flex;
        flex-direction: column;
        @media (min-width: $nav-breakpoint) {
            @for $i from 0 through 2 {
                &:nth-child( #{$i + 1} ) {
                    padding-top: $i * $row-gap;
                }
            }
        }
        &::before {
            content: attr(data-number);
            font-size: 5em;
            color: $border-color;
            line-height: 1;
            display: inline-block;

            @media (min-width: $nav-breakpoint) {
                font-size: 7em;
                align-self: flex-end;
                transform: rotate(-90deg) translateX(-30%);
                z-index: -1;
            }
        }

        &:hover::before {
            --alpha: .2;
            color: $brand-rgba;
        }
    }

}

.cssanim {
    .teaser {
        .number {
            transition: color 300ms ease-in-out;
        }
    }
}