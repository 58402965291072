*,
*:before,
*:after {
    box-sizing: border-box;
}

html {
    font-size: $base-font-size;
    line-height: $base-line-height;

    &.dark {
        --base-bg-color: #020202;
        --base-bg-rgba: 56,56,56,.7;
        --base-color: #dfdfdf;
        --headline-color: #fff;
        --border-color: rgba(236, 236, 236, .1)
    }
}

body {
    margin: 0;
    font-family: $base-font-family;
    color: $base-color;
    background-color: $base-bg-color;
}

img{
    max-width: 100%;
    height: auto;
    vertical-align: bottom;
}

a:not(#logo){
    color: $base-color;
    text-decoration-color: $brand-color;
    text-decoration-thickness: 2px;
    text-underline-offset: 2px;
    transition-delay: 150ms;

    &:hover {
        --alpha: .2;
        transition-delay: 0ms;
        color: $brand-color;
        text-decoration-color: $brand-rgba;
    }

    &.more {
        &::after {
            content: ">";
            display: inline-block;
            opacity: 0;
            transform: translateX(-100%);
        }
        &:hover {
            &::after {
                opacity: 1;
                transform: translateX(50%);
            }
        }
    }
}

.cssanim {
    a:not(#logo){
        transition: color 200ms ease-in, text-decoration 200ms ease-out;
        &.more {
            &::after {
                transition: opacity 250ms linear, transform 150ms linear;
            }
        }
    }
}

picture{
    display: block;
}

figure {
    margin: 0;
}

address {
    font-style: normal;
}