$base-font-size: 18px;
$base-line-height: 1.5;
$base-font-family: 'Inter', sans-serif;

$nav-breakpoint: 992px;
$screen-sm: 768px;
$screen-lg: 1300px;

$base-bg-color: var(--base-bg-color, #fff);
$base-bg-rgba: rgba(var(--base-bg-rgba, 255, 255, 255, 0.7));
$base-color: var(--base-color, #383838);
$headline-color: var(--headline-color, #000);
$border-color: var(--border-color, rgba(136, 136, 136, .1));

$brand-color: #D24237;
$brand-rgba: rgba(210, 66, 55, var(--alpha, 0.9));
$overlay-color: #fff;

$navbar-height: 70px;

$page-offset: 5vw;
$columns-gap: clamp(1.5rem, 4vw, 3rem);
$row-gap: 10vw;

$img-path: '../img/';