.container {
    max-width: 1350px;
    padding-left: $page-offset;
    padding-right: $page-offset;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: $nav-breakpoint) {
        padding-left: ($page-offset * 2);

    }
}

.grid {
    grid-template-columns: 1fr;
    &.grid-2 {
        @media (min-width: $nav-breakpoint) {
            grid-template-columns: 1fr 1fr;
        }
    }
    &.grid-3 {
        @media (min-width: $nav-breakpoint) {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
}

.media-text {
    grid-template-columns: 1fr;
    @media (min-width: $nav-breakpoint) {
        grid-template-columns: 50% 50%;
    }

    > .content {
        padding-left: $columns-gap;
        padding-right: $columns-gap;
    }
}
.container {
    .media-text {
        > .content {
            @media (min-width: $screen-lg) {
                padding-right: 10vw;
            }
        }
    }
}

.contact-info {
    padding: .5em 5vw 2.5em;
    font-size: 1.1em;
}
/*
.fullwidth {
    display: block;
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    width: 100vw;
    max-width: 100vw;

    img {
        width: 100%;
        max-width: unset;
    }
}
*/

.bg-lines {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 28%;
    right: 36%;
    z-index: -1;
    border-left: 1px solid $border-color;
    border-right: 1px solid $border-color;
    pointer-events: none;
}

.profil-image {
    @media (min-width: $screen-sm) {
        max-width: 35vw;
        float: right;
    }
}