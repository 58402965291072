#navbar {
    --alpha: 0.2;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    height: $navbar-height;
    padding: 0 $page-offset;
    background-color: $base-bg-rgba;
    backdrop-filter: blur(5px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid $border-color;

    @media (min-width: $nav-breakpoint) {
        right: auto;
        top: 0;
        height: auto;
        width: $page-offset;
        padding: 1.5rem 0;
        backdrop-filter: unset;
        background-color: transparent;
        flex-direction: column;
        border-top: none;
        border-right: 1px solid $border-color;
    }

    &.bg-color {
        background-color: $base-bg-color;
        backdrop-filter: unset;
    }
}

#nav-toggle {
    background: transparent;
    border: none;
    height: $navbar-height;
    width: $navbar-height;
    padding: 0;
    margin: 0;
    color: $brand-color;
    cursor: pointer;

    svg {
        //width: 60%;
        transition-delay: 400ms;
    }

    &.act svg {
        transform: rotate(90deg);
        transition-delay: 0s;
    }
}

#logo {
    display: inline-block;
    @media (min-width: $nav-breakpoint) {
        order: 1;
    }

    svg {
        height: 40px;
        fill: $brand-color;

        @media (min-width: $nav-breakpoint) {
            transform: rotate(-90deg) translateX(50%);
        }
    }
}

#menu-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: $navbar-height;
    z-index: 998;
    pointer-events: none;
    display: flex;
    align-items: flex-end;

    @media (min-width: $nav-breakpoint) {
        left: $page-offset;
        bottom: 0;
        //align-items: stretch;
    }

    .overlay {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        opacity: 0;
        background-color: $brand-color;
        z-index: -1;
    }

    &.open {
        pointer-events: auto;

        #menu-panel{
            transform: translateY(0);
            @media screen and (min-width: $nav-breakpoint) {
                transform: translateX(0);
            }
        }

        .overlay {
            opacity: .9;
        }
    }

}


#menu-panel {
    padding: 2rem 5%;
    width: 100%;
    max-height: calc(100vh - $navbar-height);
    overflow-y: auto;
    background-color: $base-bg-color;
    transform: translateY(calc(100% + $navbar-height));

    @media (min-width: $nav-breakpoint) {
        width: 450px;
        max-height: 100vh;
        height: 100vh;
        transform: translateX(calc(-100% - $page-offset));
    }
    @media (min-height: 650px){
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
}
#menu {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
        padding: .5em 0;

        &.current {
            > a {
                color: $brand-color;
            }
        }

        a {
            display: inline-block;
            text-decoration: none;
            padding: .1em 1em;
        }
    }

    .contact a {
        padding: .5em 0;
    }
}

.cssanim {
    #nav-toggle svg {
        transition: transform 140ms ease-in-out;
    }
    #menu-panel {
        transition: transform 450ms ease-out;
    }
    #menu-wrapper .overlay{
        transition: opacity 400ms linear;
    }
}