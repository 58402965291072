$columns-gap: 1.5rem !default;
$row-gap: 5rem !default;

// -- DISPLAY & POSITIONS --

.block {
    display: block;
}

.grid {
    display: grid;
}

.flex {
    display: flex;

    &.col {
        flex-direction: column;
    }

    &.ic {
        align-items: center;
    }

    &.jc {
        justify-content: center;
    }

    &.jsb {
        justify-content: space-between;
    }
}

.sticky {
    position: sticky;
    top: 0;
}


// -- Background --

.bg-clip-text {
    background-clip: text;
}

.bg-clip-content {
    background-clip: content-box;
}


// -- Gap --
.gap-r {
    margin-top: $row-gap;
    margin-bottom: $row-gap;
}

.gap-c {
    gap: $columns-gap;
}


// -- Layout --
.aspect {
    &.video {
        aspect-ratio: 16 / 9;
    }

    > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
