.slides-container {
    position: relative;
    display: flex;
    flex: 1;
    max-width: 750px;
    //border: 1px solid $border-color;
    background-color: $border-color;
    padding: 1.6rem;
    margin-top: 2em;

    .icon {
        position: absolute;
        left: 0;
        top: 0;
        transform: translate(-25%,-25%);
        fill: $base-color;
    }

    footer {
        margin-top: .5em;
        font-size: 80%;
        opacity: .8;
    }

    &::after {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 0 2em 2em;
        border-color: transparent transparent $base-bg-color transparent;
    }
}

.slides-inner {
    position: relative;
    width: 100%;
    min-height: 150px;
    overflow: hidden;
}

.slide {
    position: absolute;
    margin: 0;
    width: 100%;
}

.controls {
    padding: 10px;
    display: flex;
    height: 70px;
    min-height: 70px;

    button {
        background: transparent;
        border: none;
        margin: 0 .5em;

        svg {
            fill: $base-color
        }
        &:hover svg {
            fill: $brand-color;
        }
        &#prevButton svg {
            transform: rotate(180deg);
        }
    }
}