.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;

    &:focus {
        background-color: #eee;
        width: auto;
        height: auto;
        padding: .3em .5em;
        margin: 0;
        overflow: visible;
        clip: auto;
        white-space: normal;
        clip: auto !important;
        color: #333;
        display: inline-block;
        font-size: 1em;
        left: 5px;
        line-height: normal;
        text-decoration: none;
        top: 5px;
        z-index: 1000;
    }
}