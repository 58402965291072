.references {
    list-style-type: none;
    padding: 3em 0;
    font-size: .9em;
    @media (min-width: $screen-sm) {
        columns: 2;
        column-gap: $columns-gap;
    }

    li {
        padding-left: 1em;
        margin-bottom: 1.5em;
        box-shadow: inset 2px 0 $border-color;
        break-inside: avoid-column;

        &:hover {
            --alpha: .3;
            box-shadow: inset 2px 0 $brand-rgba;
        }
    }

    .desc {
        font-size: 80%;
    }

    .info {
        display: block;
        opacity: .5;
    }
}

.cssanim {
    .references li {
        transition: box-shadow 250ms ease-in-out;
    }
}