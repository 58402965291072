h1,
h2,
h3,
h4 {
    color: $headline-color;
}

h1 {
    font-size: clamp(1.45em, 4vw, 2.5em);
    line-height: 1.15;
    &.headline {
        font-size: clamp(1.5em, 5vw, 3.6em);
    }
}

h2 {
    font-size: clamp(1.35em, 4cqi, 1.8em);
    line-height: 1.15;
    margin-bottom: .6em;
}

h3 {
    font-size: clamp(1.2em, 3cqi, 1.35em);
    line-height: 1.25;
    margin-bottom: .6em;
}

h4 {
    font-size: 1.1em;
    line-height: 1.3;
    margin-bottom: .6em;
}

.uppercase {
    text-transform: uppercase;
}

.capitalize {
    text-transform: capitalize;
}

.hyphens-auto {
    hyphens: auto;
}

.brand {
    text-transform: uppercase;
    letter-spacing: 4px;
    font-weight: 400;
    margin: 0;

    .block {
        font-size: 1.15em;
        font-weight: 700;
        letter-spacing: 2px;
    }
}

.text-small {
    font-size: .9em;
}