.benefits {
    list-style-type: none;
    padding-left: 0;

    li {
        position: relative;
        margin: 4em 0;
        @media (min-width: $screen-sm) {
            padding-left: 3vw;
        }
    }

    [data-number],
    [data-year] {
        &::before {
            content: '';
            display: inline-block;
            position: absolute;
            left: 0;
            top:0;
            font-size: 5em;
            color: $border-color;
            line-height: 1;
            transform: translate(0, -60%);
        }
        &:hover::before {
            --alpha: .2;
            color: $brand-rgba;
        }
    }

    [data-number]::before {
        content: attr(data-number);

/*
        @media (min-width: $nav-breakpoint) {
            font-size: 7em;
            align-self: flex-end;
            transform: rotate(-90deg) translateX(-30%);
            z-index: -1;
        }

 */
    }

    [data-year]::before {
        content: attr(data-year);
    }

}